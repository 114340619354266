/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react";
import { ThemeProvider } from "./src/context/ThemeContext";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);

export const onClientEntry = () => {
  setTimeout(() => {
    document.getElementById("loader-wrapper").classList.add("open");
  }, 3000);
};
export const onPreRouteUpdate = () => {
  setTimeout(() => {
    document.getElementById("loader-wrapper").classList.add("open");
  }, 3000);
};
export const onRouteUpdateDelayed = () => {
  setTimeout(() => {
    document.getElementById("loader-wrapper").classList.add("open");
  }, 3000);
};
export const onRouteUpdate = () => {
  setTimeout(() => {
    document.getElementById("loader-wrapper").classList.remove("open");
  }, 3000);
};
